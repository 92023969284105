@import "../src/assets/tuya-theme/_global-variables";
@import "../src/assets/tuya-theme/_mixins";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "ngx-bootstrap/datepicker/bs-datepicker.scss";

$fa-font-path: "/assets/fonts";

@import "../src/assets/tuya-theme/_layout";
@import "../src/assets/tuya-theme/_stop-list";
@import "../src/assets/tuya-theme/mat-theme.scss";
@import '../src/assets/overrides/typeahead';
@import '../src/assets/overrides/data-table';

@import "ngx-bootstrap/datepicker/bs-datepicker.scss";

html, body, app-root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.scrollable {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  font-family: 'Open Sans', sans-serif;
  overscroll-behavior: none;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0px !important;
}

.tuya-select-container .tuya-select-choices li label {
  color: black !important;
  font-size: 14px;
}