@import "./_global-variables";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,100,200,300,400,600,700");
$navbrandTopBottomPadding: 45px;

@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


html {
    height: 100%;
}

body {
    background: $white;
    font-size: $font-size-base;
    width: 100%;
    height: 100%;
    
    &.modal-open {
        /* Critical for Safari browser on IOS */
        .sidebar-content.ps, .sidebar-content.ps.ps--active-y {
            position: static !important;
            overflow: visible !important;
        }
    }
    &.builder {
        background: $third-background-color;
    }
}

.modal-backdrop {
    display: none;
    visibility: hidden;
    position: relative
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}

// Override some Bootstrap styles.
.modal-content {
    border-radius: 0;
}

.modal-dialog {
    top: 120px;
}

h1, h2, h3, h4 {
    font-family: $font-family-base;
}

h1 {
    font-size: 32px;
    font-weight: $regular-font-weight;
}

h2 {
    font-size: 24px;
    font-weight: $semibold-font-weight;
}

h3 {
    font-size: 18px;
    font-weight: $bold-font-weight;
}

h4 {
    font-size: 14px;
    font-weight: $bold-font-weight;
}

.btn {

    @include no-select;
    padding: $button-padding-x $button-padding-y;
    border-radius: $button-border-radius;
    background: $secondary-button-color;
    border: none;
    text-transform: uppercase;
    color: $white;
    &:hover, &:active, &:focus, &:visited {
        color: $white;
    }
    &.btn-primary {
        background-color: $primary-button-color;
        &:focus {
            @include on-outline;
        }
        &:hover {
            background-color: $primary-button-color-hover;
        }
        &:active {
            background-color: $primary-button-color-active;
        }
    }
    &.btn-secondary {
        background-color: $secondary-button-color;
        &:hover {
            background-color: $secondary-button-color-hover;
        }
        &:active {
            background-color: $secondary-button-color-active;
        }
    }
    &.btn-default {
        background-color: $general-button-color;
        &:hover {
            background-color: $general-button-color-hover;
        }
        &:active {
            background-color: $general-button-color-active;
        }
    }
}

.btn-link.add {
    color: $orange;
    text-transform: uppercase;
    text-decoration: underline;
    line-height: 17px;
    font-size: 12px;
    font-weight: 300;
    outline: none;
    &:before {
        content: "+";
    }
    &.edit {
        &:before {
            content: " ";
            width: 20px;
            height: 20px;
        }
    }
}

.add-info-expanded {
    display: inline-block;
    height: 23px;
    line-height: 17px;
    padding: 0 0 0 20px;
    text-align: left;
    color: $orange;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    background-image: url("/assets/img/stops-list/plus_orange.svg");
    background-repeat: no-repeat;
    background-position-y: center;
    margin: 20px 0;
    &:hover {
        text-decoration: none;
        color: $orange;
        cursor: pointer;
    }
    &.expanded {
        background-image: url("/assets/img/stops-list/minus_orange.svg");
    }
    span {
        border-bottom: 1px solid $orange;
    }
}

.tuya-tooltip {
    .tooltip-inner {
        padding: 10px;
        border-radius: 2px;
        background-color: $tooltip-bgc;
        font-size: $font-size-label;
        max-width: 250px;
    }

    &.top {
        .tooltip-arrow {
            border-top-color: $tooltip-bgc;
        }
    }

    &.right {
        .tooltip-arrow {
            border-right-color: $tooltip-bgc;
        }
    }

    &.left {
        .tooltip-arrow {
            border-left-color: $tooltip-bgc;
        }
    }
}

.tuya-white-tooltip {
    opacity: 1 !important;
    .tooltip-inner {
        padding: 10px;
        border-radius: 2px;
        background-color: #ffffff;
        font-size: $font-size-label;
        max-width: 221px;
        color: #000000;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #939dab;
    }

    &.top {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-top-color: #ffffff;
        }
    }

    &.right {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-right-color: #ffffff;
        }
    }

    &.left {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-left-color: #ffffff;
        }
    }
    &.bottom {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-bottom-color: #ffffff;
            border: 1px solid #939dab;
            border-bottom: none;
            border-right: none;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: white;
        }
    }
}

.tuya-white-tooltip {
    &.undo {
        .tooltip-inner {
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
            border: solid 1px #dcdfe0;
        }
    }
    &.bottom {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-bottom-color: #ffffff;
            border: solid 1px #dcdfe0;
            border-bottom: none;
            border-right: none;
        }
    }
}

.tuya-scheduled-tooltip {
    opacity: 1 !important;
    padding-top: 8px !important;
    .tooltip-inner {
        border-radius: 2px;
        background-color: #ffffff;
        font-size: $font-size-label;
        max-width: 375px;
        font-size: 12px;
        letter-spacing: 0.19px;
        color: #333333;
        box-shadow: 0 2px 6px 0 rgba(39, 39, 39, 0.2);
        border: solid 0.6px #e6e6e6;
        padding: 10px 12px;
        text-align: left;
        margin-left: 10%;
    }
    &.top {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-top-color: #aaafb3;
        }
    }

    &.right {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-right-color: #aaafb3;
        }
    }

    &.left {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-left-color: #aaafb3;
        }
    }
    &.bottom {
        .tooltip-arrow:before,
        .tooltip-arrow {
            margin-top: 6px !important;
            border-bottom-color: #ffffff;
            border-bottom: none;
            border-right: none;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: white;
        }
    }
}

.tuya-search-tooltip-lf {
    opacity: 1 !important;
    .tooltip-inner {
        padding: 4px;
        margin-left: 110px;
        border-radius: 2px;
        background-color: #ffffff;
        font-size: $font-size-label;
        max-width: 221px;
        font-size: 12px;
        letter-spacing: 0.19px;
        color: #333333;
        box-shadow: 0 2px 7px 0 #aaafb3;
        border: 1px solid #aaafb3;
    }

    &.top {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-top-color: #aaafb3;
        }
    }

    &.right {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-right-color: #aaafb3;
        }
    }

    &.left {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-left-color: #aaafb3;
        }
    }
    &.bottom {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-bottom-color: #ffffff;
            border: 1px solid #aaafb3;
            box-shadow: 0px 2px -2px 0px #aaafb3;
            border-bottom: none;
            border-right: none;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: white;
        }
    }
}

.tuya-search-tooltip {
    opacity: 1 !important;
    .tooltip-inner {
        padding: 4px;
        border-radius: 2px;
        background-color: #ffffff;
        font-size: $font-size-label;
        max-width: 221px;
        font-size: 12px;
        letter-spacing: 0.19px;
        color: #333333;
        box-shadow: 0 2px 7px 0 #aaafb3;
        border: 1px solid #aaafb3;
    }

    &.top {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-top-color: #aaafb3;
        }
    }

    &.right {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-right-color: #aaafb3;
        }
    }

    &.left {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-left-color: #aaafb3;
        }
    }
    &.bottom {
        .tooltip-arrow:before,
        .tooltip-arrow {
            border-bottom-color: #ffffff;
            border: 1px solid #aaafb3;
            box-shadow: 0px 2px -2px 0px #aaafb3;
            border-bottom: none;
            border-right: none;
            width: 10px;
            height: 10px;
            transform: rotate(45deg);
            background: white;
        }
    }
}

.select-wrapper {
    position: relative;
    background: $third-background-color;
    > select {
        background: url('/assets/img/select-down-arrow.png') 0 0 no-repeat;
        background-position: top right;
    }
}

.form-control:not(select) {
    border-radius: $input-border-radius;
    border: $input-border;
    border-left: 5px solid transparent;
    box-shadow: none;
    background-color: $secondary-background-color;
    height: $input-height-large;
    &:focus {
        @include on-outline;
        border-left: $border-active;
    }
    &::placeholder {
        @include placeholder;
    }
}

select, .form-control-select {
    border-radius: $input-border-radius;
    border: $input-border;
    border-left: 5px solid transparent;
    box-shadow: none;
    background-color: $secondary-background-color;
    padding: 10px 20px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-border-radius: 0px;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#838388' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    &::placeholder {
        @include placeholder;
    }
}

.order-status {
    font-weight: 400;

    &.yellow {
        color: $orange-light-text;
    }

    &.red {
        color: $red;
    }

    &.blue {
        color: $blue-lighter;
    }

    &.green {
        color: $green;
    }
}

// form validation styles
.has-error {
    .form-control, .form-control-select {
        border: $input-border;
        border-left: $border-invalid;
        &:focus {
            @include on-outline;
            border-left: $border-invalid;
        }
    }
    .help-block {
        color: $red;
        text-transform: uppercase;
        font-size: 14px;
    }
}

// login form validation styles
.form-has-error {
    .form-control, .form-control-select {
        border: solid 2px $login-red !important;
        background-color: rgba(205, 32, 44, 0.1) !important;
        &:focus {
            @include on-outline;
        }
    }
    .help-block {
        color: $login-red;
        font-size: 14px;
        margin-bottom: 0px;
    }
}

.no-padding {
    padding: 0px;
}

.uppercase {
    text-transform: uppercase;
}

.sidebar-content {
    .form-control {
        background-color: $secondary-background-color;
    }
}

.valid {
    .form-control {
        border: $input-border;
        border-left: $border-valid;
        &:focus {
            @include on-outline;
            border-left: $border-valid;
        }
    }
}

// link
.link {
    font-family: $font-family-base;
    // font-size: $font-size-base;
    font-weight: $bold-font-weight;
    color: $green;
    cursor: pointer;
    &:hover {
        color: $green-darker;
        text-decoration: none;
    }
}

/*accordion styles */

.panel {
    margin-bottom: 0;
    border-radius: 0;
    padding: 0;
}

.panel-group .panel + .panel {
    margin-top: 0px;
}

.panel-group .panel {
    box-shadow: none;
    border-bottom: 1px solid #e1e1e1;
}

.panel {
    .panel-item {
        &.card {
            border: none;
            box-shadow: none;
            border-radius: 0px;
            margin-top: 0px;
            background-color: $accordion-body;
            select, .form-control-select {
                background-color: $white;
            }
            .btn-secondary {
                padding: 10px 15px;
                float: right;
            }
        }
        .card-header {
            background-color: $accordion-header;
            font-weight: $semibold-font-weight;
            padding: 15px 20px;
            font-size: 13px;
            font-weight: bold;
            color: $accordion-header-font;
            line-height: 18px;
            .panel-title {
                display: inline-block;
            }
            &:after {
                content: "\e114";
                font-family: 'Glyphicons Halflings';
                float: right;
                color: $accordion-header-font;
                margin-right: 20px;
                font-size: 13px;
                font-weight: 400;
            }
        }
    }
    &.panel-open {
        .card-header {
            &:after {
                content: ' ';
            }
        }
    }
    &.filled {
        .card-header {
            &:after {
                content: ' ';
                width: 20px;
                margin-right: 0px;
                height: 20px;
                background-size: contain;
                background-image: url("/assets/img/stops-list/completed.svg");
            }
        }
    }
}

/*tooltip styles*/
.tooltipClass {
    word-wrap: break-word;
}

.form-checkbox {
    .control {
        padding-left: 40px;
    }
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control_indicator {
        position: absolute;
        top: 3px;
        left: 0;
        height: 25px;
        width: 25px;
        background: $checkbox-color;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator {
        background: $green;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 9px;
        top: 4px;
        width: 6px;
        height: 12px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
}

timepicker {
    display: flex;
    justify-content: center;

    user-select: none;

    .btn-link {
        padding: 0 10px;
        background-color: #eee;
        color: $gray-text;
        &:hover {
            background-color: #eee;
            color: $gray-text;
        }
        &:active {
            background-color: #eee;
            color: $gray-text;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        }
    }
    .btn-default {
        padding: 10px;
        margin-right: 10px;
        background-color: #eee;
        color: $gray-text;
        &:focus {
            outline: none;
        }
    }
}

.custom-radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $gray-border;
    }

    input[type="radio"]:checked ~ .checkmark:after {
        display: block;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        left: 7px;
        top: 7px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $orange;
    }
}

.component-disabled{
    @include component-disabled;
    a.btn-link{
        @include link-disabled;
        color: $gray-dark-text;
    }
}

/*Home styles*/

.home-wrapper {
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;

    .home-container {
        position: relative;
        margin: 0 auto;
        max-width: 600px;
        color: $gray;
        -webkit-overflow-scrolling: touch;

        .circle-background {
            // width: 320px;
            // height: 320px;
            // margin: 0 auto;
            // background: $third-background-color;
            // -moz-border-radius: 160px;
            // -webkit-border-radius: 160px;
            // border-radius: 160px;
            // @media(max-width: $screen-xs) {
            //     width: 330px;
            //     height: 330px;
            //     -moz-border-radius: 165px;
            //     -webkit-border-radius: 165px;
            //     border-radius: 165px;
            // }
        }

        .home-content {
            position: absolute;
            -webkit-overflow-scrolling: touch;
            text-align: center;
            margin-top: 50px;
            width: 100%;
            @media(max-width: $screen-xs) {
                margin-top: 0px;
            }
            p {
                a, a:hover {
                    color: $orange;
                    text-decoration: none;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 18px;
                    &.proceed::after {
                        content: "\E092";
                        font-family: 'Glyphicons Halflings';
                        padding-left: 7px;
                        font-size: 11px;
                    }
                }
                margin-top: 50px;
                text-align: center;
                @media(max-width: $screen-xs) {
                    margin-top: 20px
                }
            }
        }
    }
}

::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

/* Date Picker override */
body .bs-datepicker-container {
    padding: 0;
    .bs-datepicker-head {
        background-color: transparent;
        .current {
            color: black;
        }
        .next,.previous {
            color: $gray;
        }
    }
    .bs-datepicker-body {
        span.selected {
            border-radius: 3px;
        }
    }
}