@mixin on-outline {
  outline:$input-border;
  border: $input-border;
  box-shadow: $input-border;
}

@mixin placeholder {
  font-size: $font-size-base;
  font-color: $gray-lighter;
}

@mixin reset-margin-padding {
    margin: 0;
    padding: 0;
}

@mixin text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin center-align {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin absolute {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin fixed {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin relative {
	position: relative;
	z-index: 1;
}

// DO NOT OPTIMIZE!!
@mixin single-centered-image {
    background-repeat: no-repeat;
    background-position: center;
}

@mixin component-disabled {
	h3 {
		color: $gray-dark-text;
	}
	color: $gray-dark-text;
}

@mixin link-disabled {
	pointer-events: none;
	cursor: default;
}

@mixin over-map {
    * {
        z-index: 2;
    }
}
