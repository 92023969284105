@import "../../assets/tuya-theme/_global-variables.scss";

$address-block-height: 63px;
$icon-width: 48px;
$gray-line-color: #343A4A;

.stop-list-wrapper {
    padding: 20px;

    .stop-line {
        display: flex;
        flex-direction: row;
        height: auto;
        min-height: $address-block-height;
        .data {
            border-bottom: 1px solid $gray-border-bottom;
            height: 100%;
            padding: 15px 0px;
            margin: 0px 10px;
            .title {
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
            }
            .address {
                padding: 10px 0px;
            }
            .items {
                font-size: 9px;
                text-transform: lowercase;
                font-weight: 600;
            }
        }
        .icons{
            min-height: $address-block-height;
            position: relative;
            padding: 0px;
            width: $icon-width;
            .bubble {
                width: $icon-width;
                height: 30px;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: 42px;
            }
            .line {
                height: 100%;
                width: 2px;
                border-left: 2px solid $address-line-color;
                margin: 0 auto;
                position: absolute;
                left: calc(#{$icon-width} / 2);
                &.dotted {
                    background-image: url('/assets/img/stops-list/ic_dotted_line2.svg');
                    background-repeat-y: repeat;
                    background-repeat-x: no-repeat;
                    border: none;
                }
            }
        }
        &.pickup{
            .title {
                color: $teal;
            }
            .icons {
                .bubble {
                    background: url('/assets/img/tuya-web-sprite.svg') -89px -69px no-repeat;
                    background-size: 470px 370px;
                }
            }
        }
        &.delivery, &.return {
            .title {
                color: $vermilion;
            }
            .icons {
                .bubble {
                    background: url('/assets/img/tuya-web-sprite.svg') -42px -72px no-repeat;
                    background-size: 480px 380px;
                }
            }
        }
        &.delivery-with-return, &.delivery-with-return.optional-line {
            .title {
                color: $vermilion;
            }
            .icons {
                .bubble {
                    background: url('/assets/img/tuya-web-sprite.svg') 11px -82px no-repeat;
                    background-size: 360px 350px;
                }
            }
        }
        &.optional-line {
            .title {
                color: $lbl-color;
            }
            .icons {
                .bubble {
                    background: url('/assets/img/tuya-web-sprite.svg') -330px -216px no-repeat;
                    background-size: 470px 370px;
                }
            }
        }

    }
    tuya-stop-line {
        &:first-child {
            .icons {
                .line {
                    height: 30%;
                    bottom: 0px;
                }
            }
        }
        &:last-child {
            .icons {
                .line {
                    display: none;
                }
            }
        }
    }
}
