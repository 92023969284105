// colors variables
$white: #fff !default;
$white-disabled: rgba(255,255,255,0.2);
$gray-opacity: rgba(52,58,74,0.75);

$gray: #656569 !default;
$gray-darker: #4a4a4d !default;
$gray-darker-text: #4A4A4A;
$gray-lighter: #838388 !default;
$gray-nav-transparent: rgba(101,101,105,0.5);
$gray-border: #F3F3F3;
$gray-text: #878787;
$gray-dark-text: #C6C6C6;
$gray-light-text: #4D4D4D;
$gray-darkest-text: #B3B3B3;
$gray-lightest-text: #545454;
$gray-button-background: #EFEDED;
$gray-nobel: #B5B5B5;
$gray-text-2: #C7C7CA;
$gray-address-text: #666;
$gray-border-bottom: #D8D8D8;
$gray-border-item-row: #E7E7E7;
$gray-input-background-placeholder: #c0c0c0;
$gray-stop-timeframe: #C2C2C2;
$gray-tracking-stop-item-border: #EBEBEB;
$gray-tracking-items-type: #D0D0D0;
$gray-border-notification: #f1f1f1;
$gray-border-order-history: #dfdfdf;
$gray-re-offer-toast-text: #2e2e2e;
$gray-default-password-text: #8f8f8f;
$gray-light-bluish: #e8eff4;

$error: #fe364d;
$suspended-bg: #cd202c;
$error-light: #feeaed;
$input-error-background-color: rgba(254, 54, 77, 0.1);

$admin-gray: #f1f2f3;
$admin-gray-border: #aaaaaa;
$admin-gray-border-2: #cccccc;
$admin-blue: #3187f7;

$blue: #225eac !default;
$blue-shadow: #1E549A !default;
$blue-darker:  #123f79 !default;
$blue-lighter:  #327ad8 !default;

$charcoal: #343a4a !default;
$charcoal-darker: #1b1e26 !default;
$charcoal-lighter: #4b5266 !default;

$green: #7fbc55 !default;
$green-darker: #6ca048 !default;
$green-lighter: #91d761 !default;
$green-valid-password-text: #55a341;

$teal: #1fa48d !default;
$teal-darker: #1a8b77 !default;
$teal-lighter: #26bba1 !default;

$vermilion: #f15d25 !default;
$vermilion-darker: #dd5522 !default;
$vermilion-lighter: #fc6932 !default;

$orange: #f7951d !default;
$orange-darker: #d17e19 !default;
$orange-lighter: #ffb151 !default;
$orange-light-text: #FFCD34;
$orange-bar: #F99600;

$red: #fd3850 !default;
$primary: #005db9;
$login-red: #cd202c;
$red-text: #FE3951; 
$red-invalid-password-text: #cd1f2c;

$yellow: #FED03E;

$primary-background-color: $white;
$middle-gray: #dcdfe0;
$dark-gray: #5e6a75;
$secondary-background-color: #f2f4f5;
$third-background-color: #f9f9f9;
$fourth-background-color: #f7f7f7;
$tooltip-bgc: rgba(52,58,74,0.75);

$accordion-header: #eee !default;
$accordion-header-font: #525252 !default;
$accordion-body: #F9F9F9 !default;

$icon-base-size: 17px;
$icon-order-history-size: 32px;
$image-base-size: 250px;
$navbar-width: 500px;
$spinner-color: #4682b4;
$spinner-size-big: 100px;
$spinner-size-small: 20px;

$address-line-color: #999CA4;
$tracking-shipped-color: #70778A;
$tracking-dimension-color: #535C73;

// fonts variables
$font-family-base:        "Open Sans"  !default;
$font-family-second:      "Proxima Nova";
$font-size-base:          14px !default;

$font-size-h1:            floor(($font-size-base * 2.3)) !default;
$font-size-h2:            floor(($font-size-base * 1.7)) !default;
$font-size-h3:            ceil(($font-size-base * 1.2)) !default;
$font-size-h4:            $font-size-base !default; //

$font-size-small: 10px;
$font-size-label: 12px;
$font-size-medium: 16px;
$font-size-medium-plus: 18px;
$font-size-high: 22px;
$font-size-huge: 28px;
$font-size-pay-label: 19px;

$line-height-base:        1.4 !default;
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default;
$line-height-medium: 17px;
$input-height-large: 46px;

$superlight-font-weight: 300;
$light-font-weight: 300;
$regular-font-weight: 400;
$semibold-font-weight: 600;
$bold-font-weight: 700;

//buttons colors

$primary-button-color: $orange !default;
$primary-button-color-hover: $orange-darker !default;
$primary-button-color-active: $orange-lighter !default;

$secondary-button-color: $blue !default;
$secondary-button-color-hover: $blue-darker !default;
$secondary-button-color-active: $blue-lighter !default;

$general-button-color: $charcoal !default;
$general-button-color-hover: $charcoal-darker !default;
$general-button-color-active: $charcoal-lighter !default;

$button-padding-x: 20px !default;
$button-padding-y: 40px !default;
$button-border-radius: 0px !default;

// admin panel alert botton colors
$danger-button: #cd202c;
$danger-darker: #a71a23;
$danger-button-color-hover: $danger-darker !default;

$scroll: #D8D8D8;

// inputs styles
$input-border-radius: 0px !default;
$input-border: none;
$border-active: 5px solid $orange;
$border-valid: 5px solid $green;
$border-invalid: 5px solid $red;
$input-error-border: 2px solid $error;

// nav styles
$nav-padding: 0px 18px;
$nav-background-color: #F0F0F0;
$control-panel-color: rgba($blue,0.5);

// tab styles
$tab-background-color: #DFDFDF;

// label styles
$lbl-color: #C0C0C0;
$lbl-dark-color: #333;

// checkbox styles
$checkbox-color: #eaeaea;

// select styles
$select-bgc: #EFEDED;
$select-border-color: #C8C8C8;

$no-account-btn: #C1C1C1;

// min heights
$min-height-container: 800px;

//modal styles
$modal-width: 516px;

// img size
$driver-photo-size: 74px;

// circle-size

$circle-size: 200px;

//screen sizes
$screen-xs: 576px;

//dark line
$dark-line: #676767;
