@use '@angular/material' as mat;
@include mat.core();

// Define custom typography
/*$general-typography: mat.define-typography((
  font-family: '"Open Sans", "Helvetica Neue", sans-serif',
  headline-1: mat.typography-level(96px, 96px, 300),
  headline-2: mat.typography-level(60px, 64px, 300),
  body-1: mat.typography-level(16px, 24px, 400),
  button: mat.typography-level(14px, 16px, 500),
));

// Apply typography hierarchy
@include mat.all-component-typographies($general-typography);

/*$general-typography: mat-typography-config($font-family: '"Open Sans", "Helvetica Neue", sans-serif;');
$general-typography: mat.define-typography-config(
  $font-family: '"Open Sans", "Helvetica Neue", sans-serif',
);
@include mat-typography-hierarchy($general-typography);*/

$blue: (
    50 : #e0ecf7,
    100 : #b3ceea,
    200 : #80aedc,
    300 : #4d8ece,
    400 : #2675c4,
    500 : #005db9,
    600 : #0055b2,
    700 : #004baa,
    800 : #0041a2,
    900 : #003093,
    A100 : #beceff,
    A200 : #8ba8ff,
    A400 : #5881ff,
    A700 : #3f6eff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

$tuya-blue: (
    50 : #e4ecf5,
    100 : #bdcfe6,
    200 : #91afd6,
    300 : #648ec5,
    400 : #4376b8,
    500 : #225eac,
    600 : #1e56a5,
    700 : #194c9b,
    800 : #144292,
    900 : #0c3182,
    A100 : #b3c7ff,
    A200 : #80a2ff,
    A400 : #4d7cff,
    A700 : #336aff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$my-app-primary: mat.m2-define-palette($tuya-blue);
$my-app-accent: mat.m2-define-palette($blue);
$my-app-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette);
/* $tuya-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn); */
$tuya-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-app-primary,
      accent: $my-app-accent,
      warm: $my-app-warn,
    )
  )
);

@include mat.all-component-themes($tuya-theme);